var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IconWrapper', [_vm.cell.label ? [_c('span', {
    class: {
      disabled: _vm.cell.disabled
    },
    attrs: {
      "title": _vm.cell.tooltipLabel
    }
  }, [_vm._v(_vm._s(_vm.cell.label) + " ")])] : _c('span', {
    attrs: {
      "title": _vm.cell.noData ? _vm.cell.noData : _vm.$t('messages.noDataAvailable')
    }
  }, [_vm._v("-")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }