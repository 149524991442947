<template>
  <PlainCellStyled class="cell" :isMobile="isMobile" :panel="cell.panel">
    <router-link :to="{ name: cell.linkName, params: cell.linkParams }">{{ cell.linkLabel }}</router-link>
  </PlainCellStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const PlainCellStyled = styled('div')`
  display: flex;
  align-items: center;
  @media (min-width: 1024px) {
    padding-left: 0;
    ${({ isMobile, panel }) => !isMobile && !panel && `padding-left: 3rem`};
  }
  padding: 0.5rem;
`

export default {
  props: {
    cell: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    panel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PlainCellStyled,
  },
}
</script>
