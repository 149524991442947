<template>
  <SeverityLampStyled>
    <slot name="icon" />
  </SeverityLampStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const SeverityLampStyled = styled('div')`
  grid-area: lamp;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.colors.atomic.severityLampOff};
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  &.priority {
    background: ${({ theme }) => theme.colors.atomic.severityPriority};
    &.active {
      box-shadow: 0 0 18px 4px ${({ theme }) => theme.colors.atomic.severityPriority};
    }
  }
  &.red {
    background: ${({ theme }) => theme.colors.atomic.severityRed};
    &.active {
      box-shadow: 0 0 18px 4px ${({ theme }) => theme.colors.atomic.severityRed};
    }
  }
  &.amber,
  &.yellow {
    background: ${({ theme }) => theme.colors.atomic.severityAmber};
    &.active {
      box-shadow: 0 0 18px 4px ${({ theme }) => theme.colors.atomic.severityAmber};
    }
  }
  &.malfunction {
    background: ${({ theme }) => theme.colors.atomic.severityMalfunction};
    &.active {
      box-shadow: 0 0 18px 4px ${({ theme }) => theme.colors.atomic.severityMalfunction};
    }
  }
  &.protection {
    background: ${({ theme }) => theme.colors.atomic.severityProtection};
    &.active {
      box-shadow: 0 0 18px 4px ${({ theme }) => theme.colors.atomic.severityProtection};
    }
  }
  &.green {
    background: ${({ theme }) => theme.colors.atomic.statusGreen};
  }
`

export default {
  components: {
    SeverityLampStyled,
  },
  props: {},
  computed: {},
}
</script>
