<template>
  <IconWrapper>
    <template v-if="status" @click="toggleOptions(cell.assetId)">
      <VPopover
        :popperOptions="popperOptions"
        :placement="'bottom-start'"
        :autoHide="false"
        :trigger="'hover'"
        :open="showPopup === cell.assetId"
        @hide="hideOptions"
        :popoverClass="'userGuidePopOver'"
        :container="'#app'"
      >
        <span class="popover-trigger">
          <TalpaIcon :isCustomColor="true" :scope="'HealthStatusLarge'" :name="status" />
        </span>
        <template v-slot:popover>
          <MainWrapperStyled v-if="cell.tooltipContent.length">
            <NotificationListStyled v-for="(notification, i) in cell.tooltipContent" :key="i">
              <router-link :to="to(notification)" :class="{ disabled: cell.disableNav }" class="route">
                <div class="severities">
                  <SeverityLampsMolecule :severity="notification.severity" />
                </div>
                <div class="code">
                  <strong>{{ $tc('code', 1) | capitalize }}:</strong>
                  <span>#{{ notification.code }}</span>
                </div>
                <div class="label">
                  <strong>{{ $tc('label', 1) | capitalize }}:</strong>
                  <span>{{ notification.label }}</span>
                </div>
                <div class="start">
                  <strong>{{ $tc('start', 1) | capitalize }}:</strong>
                  <span>{{ formattedStartTime(notification.start) }}</span>
                </div>
                <div class="duration">
                  <strong>{{ $tc('duration', 1) | capitalize }}:</strong>
                  <span> {{ notification.duration | secondsTohhmmss }} </span>
                </div>
              </router-link>
            </NotificationListStyled>
          </MainWrapperStyled>
          <template v-else>
            <NoNotificationsStyled>{{ $t('shiftReportEvent.shiftGeneralInformation.noActiveFailure') }} </NoNotificationsStyled>
          </template>
        </template>
      </VPopover>
      <span class="status">{{ healthStatus }}</span>
    </template>
    <span class="message" v-else :title="cell.noData ? cell.noData : $t('messages.noDataAvailable')">-</span>
  </IconWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { TalpaIcon } from '@common/components'
import { get } from 'lodash'
import { VPopover } from 'v-tooltip'
import chroma from 'chroma-js'
import { secondsTohhmmss } from '@/utils/filters/time'
import SeverityLampsMolecule from '@/components/Atomic/Molecules/ShiftInsightsSeverityLampsMolecule'
import localesMixin from '@/mixins/locales'
import permissionsMixin from '@/mixins/permissions'

import { formatDateAccordingToSettings } from '@/utils/time'

const IconWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;

  > div,
  svg.customColor {
    width: 25px;
    height: 25px;
    path#backDrop {
      fill: ${({ theme }) => theme.colors.atomic.healthStatusIconBG};
    }
  }
  .status,
  .message {
    display: flex;
    margin-left: 0.5rem;
    cursor: default;
  }
  .popover-trigger {
    cursor: pointer;
  }
`
const NoNotificationsStyled = styled('div')`
  color: ${({ theme }) => theme.colors.atomic.textMain};
  cursor: pointer;
  align-items: center;
  grid-gap: 0.75rem;
  background: ${({ theme }) => theme.colors.solidBG};
  box-shadow: 0px 1px 4px ${({ theme }) => chroma(theme.colors.black).alpha(0.1).css()};
  padding: 0.5rem;
  border-radius: 0.5rem;
`
const NotificationListStyled = styled('div')`
  .disabled {
    pointer-events: none;
  }
  > .route {
    text-decoration: unset;
    grid-column: span 2;
    color: ${({ theme }) => theme.colors.atomic.textMain};
    cursor: pointer;
    display: grid;
    align-items: center;
    grid-template-columns: min-content auto max-content max-content;
    grid-template-areas:
      'severities code start duration'
      'label label label label';
    grid-gap: 0.75rem;
    background: ${({ theme }) => theme.colors.solidBG};
    box-shadow: 0px 1px 4px ${({ theme }) => chroma(theme.colors.black).alpha(0.1).css()};
    padding: 0.5rem;
    border-radius: 0.5rem;
    &:hover {
      background: ${({ theme }) => chroma(theme.colors.solidBG).alpha(0.75).css()};
      color: ${({ theme }) => theme.colors.atomic.textMain};
    }
    @media (max-width: 1024px) {
      grid-template-columns: min-content min-content 1fr 1fr;
      grid-template-rows: min-content;
      grid-template-areas:
        'severities code'
        'label label'
        'start start'
        'duration duration';
      .start,
      .duration,
      .label {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    .code,
    .label,
    .duration,
    .start {
      display: flex;
      gap: 0.25rem;
    }

    .code {
      grid-area: code;
      align-items: center;
    }
    .label {
      grid-area: label;
    }
    .severities {
      grid-area: severities;
    }
    .start {
      grid-area: start;
    }
    .duration {
      grid-area: duration;
    }
  }
`

const MainWrapperStyled = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  overflow: auto;
  max-height: 14rem;
  gap: 0.25rem;
  background: ${({ theme }) => chroma(theme.colors.mainBG).alpha(0.9).css()};
`

export default {
  mixins: [localesMixin, permissionsMixin],

  props: {
    cell: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPopup: '',
    }
  },
  filters: {
    secondsTohhmmss,
  },
  components: {
    IconWrapper,
    TalpaIcon,
    VPopover,
    MainWrapperStyled,
    NotificationListStyled,
    SeverityLampsMolecule,
    NoNotificationsStyled,
  },
  computed: {
    healthStatus() {
      return this.status ? `${this.$tc('assetHealthStatus.' + this.status)}` : ''
    },
    status() {
      return get(this.cell, 'status', null)
    },
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            boundariesElement: 'scrollParent',
            escapeWithReference: true,
          },
        },
      }
    },
    hasMaintenanceAppPermission() {
      return Boolean(this.hasMaintenanceAppV2Permission)
    },
    hasSDAppPermission() {
      return Boolean(this.hasServiceDealerAppPermission)
    },
    routeName() {
      let routeName = 'AssetHealthInsight'
      if (this.hasMaintenanceAppPermission && this.hasSDAppPermission) {
        const selectedApp = get(this.uiSettings, 'defaultApp', 'MAINTENANCE_APP')
        routeName = selectedApp === 'SD_APP' ? 'ServiceDealerAssetHealthInsight' : 'AssetHealthInsight'
      } else if (!this.hasMaintenanceAppPermission && this.hasSDAppPermission) {
        routeName = 'ServiceDealerAssetHealthInsight'
      }
      return routeName
    },
  },
  methods: {
    formattedStartTime(time) {
      return formatDateAccordingToSettings(time, this.uiSettings, this.selectedTimezone)
    },
    to(notification) {
      const assetHealthInsightRoute = {
        name: this.routeName,
        params: {
          id: this.cell.assetId,
        },
      }
      if (notification.issueId) {
        assetHealthInsightRoute.query = {
          issue: notification.issueId,
        }
      }
      return assetHealthInsightRoute
    },
    toggleOptions(id) {
      this.showPopup = ''
      this.showPopup = id
    },
    hideOptions() {
      this.showPopup = ''
    },
  },
}
</script>
