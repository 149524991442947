import { DateTime } from 'luxon'

export function timeSimple(value, locale) {
  if (!DateTime.isDateTime(value)) {
    value = DateTime.fromISO(value)
  }
  if (value.isValid) {
    if (locale) {
      if (locale === 'de-de') {
        return value.setLocale(locale).toLocaleString(Object.assign(DateTime.TIME_SIMPLE, { hour: '2-digit' }))
      }
      return value.setLocale(locale).toLocaleString(DateTime.TIME_SIMPLE)
    }
    return value.toLocaleString(DateTime.TIME_SIMPLE)
  } else {
    return value
  }
}

export function dateTimeShort(value, locale) {
  if (!DateTime.isDateTime(value)) {
    value = DateTime.fromISO(value)
  }
  if (value.isValid) {
    if (locale) {
      return value.setLocale(locale).toLocaleString(DateTime.DATETIME_SHORT)
    }
    return value.toLocaleString(DateTime.DATETIME_SHORT)
  } else {
    return value
  }
}

export function dateTimeFull(value, locale) {
  if (!DateTime.isDateTime(value)) {
    value = DateTime.fromISO(value)
  }
  if (value.isValid) {
    if (locale) {
      return value.setLocale(locale).toLocaleString(DateTime.DATETIME_FULL)
    }
    return value.toLocaleString(DateTime.DATETIME_FULL)
  } else {
    return value
  }
}

export function dateFull(value, locale) {
  if (!DateTime.isDateTime(value)) {
    value = DateTime.fromISO(value)
  }
  if (value.isValid) {
    if (locale) {
      return value.setLocale(locale).toLocaleString(DateTime.DATE_FULL)
    }
    return value.toLocaleString(DateTime.DATE_FULL)
  } else {
    return value
  }
}

export function dateFullWithWeekday(value, locale) {
  if (!DateTime.isDateTime(value)) {
    value = DateTime.fromISO(value)
  }
  if (value.isValid) {
    if (locale) {
      return value
        .setLocale(locale)
        .toLocaleString(Object.assign(DateTime.DATE_MED, { weekday: 'long', day: '2-digit', month: '2-digit', year: '2-digit' }))
    }
    return value.toLocaleString(Object.assign(DateTime.DATE_MED, { weekday: 'long', day: '2-digit', month: '2-digit', year: '2-digit' }))
  } else {
    return value
  }
}

export function dateTimeByGranularity(value, locale, granularity) {
  if (granularity === 'P1D') {
    return dateFullWithWeekday(value, locale)
  } else if (granularity === 'PT1H') {
    return timeSimple(value, locale)
  }
  return `invalid granularity ${granularity}`
}

export function secondsTohhmmss(totalSeconds) {
  var hours = Math.floor(totalSeconds / 3600)
  var minutes = Math.floor((totalSeconds - hours * 3600) / 60)
  var seconds = totalSeconds - hours * 3600 - minutes * 60
  seconds = Math.round(seconds * 100) / 100
  var result = hours < 10 ? '0' + hours : hours
  result += ':' + (minutes < 10 ? '0' + minutes : minutes)
  result += ':' + (seconds < 10 ? '0' + seconds : seconds)
  return result
}
