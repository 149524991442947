<template>
  <SeverityLampsStyled>
    <SeverityLampAtom :class="severityClass" />
  </SeverityLampsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import SeverityLampAtom from '@/components/Atomic/Atoms/SeverityLampAtom'

const SeverityLampsStyled = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

export default {
  components: {
    SeverityLampsStyled,
    SeverityLampAtom,
  },
  props: {
    severity: {
      type: String,
    },
  },
  computed: {
    severityClass() {
      return this.severity.toLowerCase()
    },
  },
}
</script>
