var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IconWrapper', [_vm.status ? [_c('VPopover', {
    attrs: {
      "popperOptions": _vm.popperOptions,
      "placement": 'bottom-start',
      "autoHide": false,
      "trigger": 'hover',
      "open": _vm.showPopup === _vm.cell.assetId,
      "popoverClass": 'userGuidePopOver',
      "container": '#app'
    },
    on: {
      "hide": _vm.hideOptions
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_vm.cell.tooltipContent.length ? _c('MainWrapperStyled', _vm._l(_vm.cell.tooltipContent, function (notification, i) {
          return _c('NotificationListStyled', {
            key: i
          }, [_c('router-link', {
            staticClass: "route",
            class: {
              disabled: _vm.cell.disableNav
            },
            attrs: {
              "to": _vm.to(notification)
            }
          }, [_c('div', {
            staticClass: "severities"
          }, [_c('SeverityLampsMolecule', {
            attrs: {
              "severity": notification.severity
            }
          })], 1), _c('div', {
            staticClass: "code"
          }, [_c('strong', [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('code', 1))) + ":")]), _c('span', [_vm._v("#" + _vm._s(notification.code))])]), _c('div', {
            staticClass: "label"
          }, [_c('strong', [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('label', 1))) + ":")]), _c('span', [_vm._v(_vm._s(notification.label))])]), _c('div', {
            staticClass: "start"
          }, [_c('strong', [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('start', 1))) + ":")]), _c('span', [_vm._v(_vm._s(_vm.formattedStartTime(notification.start)))])]), _c('div', {
            staticClass: "duration"
          }, [_c('strong', [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('duration', 1))) + ":")]), _c('span', [_vm._v(" " + _vm._s(_vm._f("secondsTohhmmss")(notification.duration)) + " ")])])])], 1);
        }), 1) : [_c('NoNotificationsStyled', [_vm._v(_vm._s(_vm.$t('shiftReportEvent.shiftGeneralInformation.noActiveFailure')) + " ")])]];
      },
      proxy: true
    }], null, false, 3862353665)
  }, [_c('span', {
    staticClass: "popover-trigger"
  }, [_c('TalpaIcon', {
    attrs: {
      "isCustomColor": true,
      "scope": 'HealthStatusLarge',
      "name": _vm.status
    }
  })], 1)]), _c('span', {
    staticClass: "status"
  }, [_vm._v(_vm._s(_vm.healthStatus))])] : _c('span', {
    staticClass: "message",
    attrs: {
      "title": _vm.cell.noData ? _vm.cell.noData : _vm.$t('messages.noDataAvailable')
    }
  }, [_vm._v("-")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }