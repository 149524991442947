<template>
  <IconWrapper>
    <template v-if="cell.label">
      <span
        :class="{
          disabled: cell.disabled,
        }"
        :title="cell.tooltipLabel"
        >{{ cell.label }}
      </span>
    </template>
    <span v-else :title="cell.noData ? cell.noData : $t('messages.noDataAvailable')">-</span>
  </IconWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const IconWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 3rem;
  gap: 1rem;
  > span {
    display: flex;
    cursor: default;
    &.disabled {
      opacity: 0.5;
      color: ${p => p.theme.colors.navFontNormal};
    }
  }
`

export default {
  props: {
    cell: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconWrapper,
  },
  computed: {},
}
</script>
